const sgm_data = [
  {
    id: 1,
    date: "23 January 2024",
    time: "7pm",
    pdf: "sgm/sgm2.pdf",
  },
  {
    id: 2,
    date: "29 October 2023",
    time: "10:00 am",
    pdf: "sgm/sgm1.pdf",
  },
  {
    id: 3,
    date: "PMC Appointment",
    time: "--",
    pdf: "sgm/pmc_appointment.pdf",
  },
  {
    id: 4,
    date: "10 April 2022",
    time: "7pm",
    pdf: "sgm/sgm3.pdf",
  },
  {
    id: 5,
    date: "20 August 2023",
    time: "7pm",
    pdf: "sgm/sgm4.pdf",
  },
  {
    id: 6,
    date: "26 May 2024",
    time: "7pm",
    pdf: "sgm/sgm5.pdf",
  },
  {
    id: 7,
    date: "22 October 2023",
    time: "7pm",
    pdf: "sgm/sgm6.pdf",
  },
  {
    id: 8,
    date: "7 January 2024",
    time: "9:30 am",
    pdf: "sgm/sgm7.pdf",
  },
  {
    id: 9,
    date: "24 Septemeber 2023",
    time: "11 am",
    pdf: "sgm/sgm8.pdf",
  },
  {
    id: 10,
    date: "30 June 2024",
    time: "--",
    pdf: "sgm/sgm9.pdf",
  },
];

export default sgm_data;